import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Organization, OrgType } from '../models/Organization';


const httpOptionsNoToken = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  })
};

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  apiOrganizationUrl = environment.apiUrl + '/organization';

  constructor(
    private http: HttpClient,
  ) { }

  returnUrl() {
    return this.apiOrganizationUrl;
  }

  registOrganization(
    username: string, 
    email: string, 
    fullname: string, 
    password: string, 
    cellphone: string, 
    sd_schul_code: string, 
    org_name: string, 
    atpt_ofcdc_sc_code: string, 
    atpt_ofcdc_sc_nm: string, 
    org_rdnzc: string, 
    org_rdnma: string, 
    org_rdnda: string, 
    regist_file: File
  ): Observable<any> {
  
    const formData: FormData = new FormData();
  
    formData.append('username', username);
    formData.append('email', email);
    formData.append('fullname', fullname);
    formData.append('password', password);
    formData.append('cellphone', cellphone);
    formData.append('sd_schul_code', sd_schul_code);
    formData.append('org_name', org_name);
    formData.append('atpt_ofcdc_sc_code', atpt_ofcdc_sc_code);
    formData.append('atpt_ofcdc_sc_nm', atpt_ofcdc_sc_nm);
    formData.append('org_rdnzc', org_rdnzc);
    formData.append('org_rdnma', org_rdnma);
    formData.append('org_rdnda', org_rdnda);
    formData.append('regist_file', regist_file, regist_file.name);
  
    return this.http.post(this.apiOrganizationUrl + '/regist', formData);
  }

  registOrganizationName(
    username: string,
    email: string,
    fullname: string,
    password: string,
    cellphone: string,
    org_name: string,
    regist_file: File
  ): Observable<any> {
  
    const formData: FormData = new FormData();
  
    formData.append('username', username);
    formData.append('email', email);
    formData.append('fullname', fullname);
    formData.append('password', password);
    formData.append('cellphone', cellphone);
    formData.append('org_name', org_name);
    formData.append('regist_file', regist_file, regist_file.name);
  
    return this.http.post(this.apiOrganizationUrl + '/regist/name', formData);
  }
  
  validOrganization(sd_schul_code): Observable<any> {
    const body = {
      sd_schul_code: sd_schul_code
    }
    return this.http.post(this.apiOrganizationUrl + '/valid', body, httpOptionsNoToken);
  }

  validOrganizationName(org_name): Observable<any> {
    const body = {
      org_name: org_name
    }
    return this.http.post(this.apiOrganizationUrl + '/valid/name', body, httpOptionsNoToken);
  }

  signinOrganization(userInfo): Observable<any> {
    return this.http.post(this.apiOrganizationUrl + '/signin', userInfo, httpOptionsNoToken);
  }

  getOrganizationForAdminUser(): Organization {
    const orgDataRaw = localStorage.getItem('organization');
    if (orgDataRaw === null || orgDataRaw === undefined) {
      return null;
    }
    const orgData = JSON.parse(orgDataRaw);

    const userDataRaw = localStorage.getItem('user');
    if (userDataRaw === null || userDataRaw === undefined) {
      return null;
    }
    const userData = JSON.parse(userDataRaw);

    const currentOrg = new Organization(orgData.id, orgData.name, userData.email, [], [], '../../../../assets/images/defpf.svg', OrgType.School, userData.cellphone);
    return currentOrg;
  }

  /**
  // fetch all users to find the logged-in AdminUser (can be either SuperAdmin or SubAdmin)
    const users = this.admUserService.getAdminUsers();

    // find the logged-in AdminUser
    const adminUser = users.find(user => {
      return (user as any).admUserType !== undefined;
    })

    // if an AdminUser is found, fetch the associated organization
    if (adminUser) {
      const orgId = adminUser.getOrgId();
      const orgKey = `${this.storageKeyPrefix}${orgId}`;
      const org = localStorage.getItem(orgKey);
      if (org) {
        return this.deserializeOrganization(JSON.parse(org));
      }
    }

    return null;
   */

  // save or update an organization
}
