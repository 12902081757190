import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { timer } from 'rxjs';
import { FixedSizeVirtualScrollStrategy } from '@angular/cdk/scrolling';
import { AdminService } from './shared/services/admin.service';
import { MAT_DATE_FORMATS } from '@angular/material/core';

// custom date formats for Angular MatDatePicker
export const CUSTOM_DATE_FORMATS = {
  parse: {
    dateInput: 'YYYY.MM.DD',
  },
  display: {
    dateInput: 'YYYY.MM.DD',
    monthYearLabel: 'YYYY.MM',
    dateA11yLabel: 'YYYY.MM.DD',
    monthYearA11yLabel: 'YYYY.MM'
  },
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'TBoxMedia';
  collapedSideBar: boolean;

  receiveCollapsed($event) {
    this.collapedSideBar = $event;
  }

  lang: string = "";
  // subDir: string = "";
  isLive = false;

  constructor(
    private userService: AdminService,
    public router: Router,
  ){
    timer(0, 300000).subscribe(() => {
      if (localStorage.getItem('token')){
        const tokenInfo = JSON.parse(localStorage.getItem('token'));
        if (tokenInfo?.refresh) {
          if(Date.parse(tokenInfo?.refresh_expire) > Date.now()){
            /**
             * this.userService.refreshToken(tokenInfo?.refresh).subscribe(
              data => {
                tokenInfo.access = data.access;
                tokenInfo.access_expire = data.access_expire;
                // commented out to transition from TBox Main page to Admin page
                //localStorage.setItem('user', JSON.stringify(data.user));
                //localStorage.setItem('token', JSON.stringify(tokenInfo));
              }
            )
             */
          } else {
            localStorage.clear();
            location.reload();
          }
        } else if(Date.parse(tokenInfo?.access_expire) < Date.now()){
          localStorage.clear();
          location.reload();
        }
      }
    });

    this.lang = location.pathname.substr(1,2);
    // this.subDir = location.pathname.substr(4,4);
    // console.log(this.subDir);
    if( this.lang !== "kr" && this.lang !== "en" ) {
      this.lang = "kr"
    }
    // if(location.pathname.includes('live')){
      // if(location.href=='https://tboxtv.com/' || location.href=='https://tboxtv.com'){
      //   this.isLive = true;
      //   this.router.navigate(['live/home']);
      // }
      // else if(location.hostname.includes('tboxtv.com')){
      //   this.isLive = true;
      // }
      // else if(location.hostname.includes('tboxfit.com')){
      // if(sessionStorage.getItem('doNotCheckIpLocation') == null){
      //   this.userService.getClientIp().subscribe(
      //     data => {
      //       if(location.pathname.includes('tbfapp')) return;
      //       if(data.country.country_code=='KR' && !location.pathname.includes('/kr/')){
      //         if(confirm('한국어 버전으로 전환하시겠습니까?')){
      //           this.router.navigate(['kr/home']);
      //         } else {
      //           sessionStorage.setItem('doNotCheckIpLocation', 'true');
      //         }
      //       } else if ( data.country.country_code!='KR' && !location.pathname.includes('/en/')) {
      //         if(confirm('Would you like to switch to the english version of the website?')){
      //           this.router.navigate(['en/home']);
      //         } else {
      //           sessionStorage.setItem('doNotCheckIpLocation', 'true');
      //         }
      //       } else {
      //         // No language check
      //       }
      //     }, error => { console.log(error); }
      //   )
      // }
    // }
  }

  ngOnInit(): void {
    if (window.innerWidth < 999) {
      this.router.navigate(['/unsupported']);
    }
  }
}

// 스크롤 이벤트
$(document).ready(function() {
  $(".bottom_menu").click(function() {
    $(window).scrollTop(0);
  });

  $(window).scroll(function() {
    var scrTop = $(this).scrollTop();
    if (scrTop > 100) {
      $('.scrControl').fadeIn();
    } else {
      $('.scrControl').fadeOut();
    }
  });


  $('.scrTop').click(function() {
    $('html,body').stop().animate({
      scrollTop : 0
    });
  });
  $('.scrBottom').click(function() {
    $('html,body').stop().animate({
      scrollTop : $(document).height()
    });
  });
});

// live room nav
// $(document).ready(function(){
//   $('.nav-m').click(function(){
//     $('.slider_nav').toggleClass('side_tog');
//     $('.nav-bg').toggleClass('side_tog_bg');
//   })
//   $('.nav-bg, .slider_nav .close_btn, .slider_nav .nav-list-m').click(function(){
//     $('.slider_nav').removeClass('side_tog');
//     $('.nav-bg').removeClass('side_tog_bg');
//   })
// });
$(document).on("click",".nav-m",function(){
  $('.slider_nav').toggleClass('side_tog');
  $('.nav-bg').toggleClass('side_tog_bg');
});
$(document).on("click",".nav-bg, .slider_nav .close_btn, .slider_nav .nav-list-m",function(){
  $('.slider_nav').toggleClass('side_tog');
  $('.nav-bg').toggleClass('side_tog_bg');
});
