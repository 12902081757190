import { Component } from '@angular/core';

@Component({
  selector: 'app-unsupported',
  template: `
    <div style="text-align:center; margin-top:20px;">
      <h1>지원되지 않는 화면 크기</h1>
      <p>현재 화면 크기는 지원되지 않습니다. 해상도를 조정하거나 다른 기기를 사용해주세요.</p>
    </div>
  `,
})
export class UnsupportedComponent {}
