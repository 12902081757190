import { Renderer2, Component, OnInit, OnDestroy, Inject, ViewChild, ElementRef } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AdminService } from 'src/app/shared/services/admin.service';
import { OrganizationService } from 'src/app/shared/services/organization.service';
import { SchoolSearchService } from 'src/app/shared/services/school-search.service';
import { MustMatch } from './must-match.validator';
import { DOCUMENT } from '@angular/common';
import { Organization, OrgType } from 'src/app/shared/models/Organization';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css'],
  animations: [
    trigger('toggleDiv', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.3s ease', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('0.3s ease', style({ opacity: 0 })),
      ]),
    ]),
  ]
})
export class SignupComponent implements OnInit, OnDestroy {
  private _agreeTos!: ElementRef;
  private _agreePriv!: ElementRef;
  private _agreeMem!: ElementRef;
  private _agreeAll!: ElementRef;

  @ViewChild('agreeTos') set agreeTos(content: ElementRef) {
    this._agreeTos = content;
  }
  @ViewChild('agreePriv') set agreePriv(content: ElementRef) {
    this._agreePriv = content;
  }
  @ViewChild('agreeMem') set agreeMem(content: ElementRef) {
    this._agreeMem = content;
  }
  @ViewChild('agreeAll') set agreeAll(content: ElementRef) {
    this._agreeAll = content;
  }

  // form controls
  registerForm: FormGroup;
  formSubmitted = false;
  errorExists = false;
  checkbox = false;

  // school search results modal
  showModal: boolean = false;
  orgSearchInput: string = '';
  orgSearchResults: any[] = [];
  orgCode: number = 0;
  orgDup: boolean = false;
  chkOrgDup: boolean = false;
  selectedOrg: any;
  storedOrg: Organization;

  // school search results pagination
  paginatedResults: any[] = [];
  currentPage: number = 1;
  pageSize: number = 10;
  totalPages: number = 0;
  startIndex: number = 0;
  endIndex: number = 0;

  // username duplicate check
  dupUsername: string = ''; // temporarily store violating (duplicate) username to compare with input changes and withdraw error message
  checkUsernameText: string = "아이디 중복 확인";
  usernameExists: boolean = false;
  checkUsernameDup;

  // email duplicate check
  dupEmail: string = ''; // temporarily store violating (duplicate) email to compare with input changes and withdraw error message
  checkEmailText: string = '이메일 중복 확인';
  emailExists: boolean = false;

  // password visibility controls
  passwordVisible: boolean = false; // password visibility toggle
  visibilityTimeout: any; // password visibility timeout

  // file upload function
  fileUploaded: boolean = false;
  uploadedFiles: File[] = [];
  maxFileSize: number = 20 * 1024 * 1024; // 20 MB in bytes
  maxFiles = 1;

  youtubeSubImg = null;

  myScript;

  // register steps (1-3); conditionally render form elements with *ngIf according to step
  registerStep: number = 1;

  // booleans for TOS dropdown rendering
  showTos: boolean = false;
  showPrivTerms: boolean = false;
  showMemTerms: boolean = false;
  // booleans for TOS agreement status
  tosAgree: boolean = false;
  privAgree: boolean = false;
  memAgree: boolean = false;
  allAgree: boolean = false;
  agreeSubmitted: boolean = false;
  canFinishRegister: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    public router: Router,
    private userService: AdminService,
    private organizationService: OrganizationService,
    // private member: Member
    private _renderer2: Renderer2,
    private schoolSearchService: SchoolSearchService,
    @Inject(DOCUMENT) private _document: Document
  ) {
  }

  ngOnInit() {
    this.errorExists = false;
    this.registerForm = this.formBuilder.group({
      typeSelect: ['', Validators.required],
      organization: new FormControl('', {
        validators: [Validators.required, Validators.maxLength(20)],
        updateOn: 'change'  // specify updateOn here
      }),
      organizationModal: new FormControl('', {
        validators: [Validators.required, Validators.maxLength(20)],
        updateOn: 'change'
      }),
      username: ['',
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(20),
          Validators.pattern(/^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]+$/) // at least one letter and one number
        ]
      ],
      usernameCheckedUnique: [false, Validators.requiredTrue],
      emailCheckedUnique: [false, Validators.requiredTrue],
      password: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(50),
        Validators.pattern(/(?!.*pass|.*word|.*1234|.*qwer|.*asdf|.*abc|.*aaaa|.*000|.*111|.*222|.*333|.*444|.*555|.*666|.*777|.*888|.*999|.*123|.*012)^(?=(.*[0-9]){1,})(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[`~!@#\$%\^&*\-_=\+\|\\<>?]){1,})([a-zA-Z0-9\`~!@#\$%\^&\*\-_=+|\\<>/?]+)$/)
      ]],
      cpassword: ['', [Validators.required, Validators.maxLength(50)]],
      email: ['', [Validators.required, Validators.email, Validators.maxLength(50)]],
      fullname: ['', [Validators.required, Validators.maxLength(50)]],
      cnum1: ['', [Validators.min(10), Validators.max(999), Validators.required]],
      cnum2: ['', [Validators.min(100), Validators.max(9999), Validators.required]],
      cnum3: ['', [Validators.min(1000), Validators.max(9999), Validators.required]],
      acceptTerms: [false, Validators.requiredTrue]
    }, { validator: MustMatch('password', 'cpassword') });

    // reset usernameCheckedUnique when username value changes after entering duplicate
    // the trim() method removes whitespace for input sanitization.
    this.registerForm.get('username').valueChanges.subscribe((value) => {
      this.rf['usernameCheckedUnique'].setValue(false);
      this.registerForm.get('username').setValue(value.trim(), { emitEvent: false });
    })

    // reset emailCheckedUnique when username value changes after entering duplicate
    this.registerForm.get('email').valueChanges.subscribe((value) => {
      this.rf['emailCheckedUnique'].setValue(false);
      this.registerForm.get('email').setValue(value.trim(), { emitEvent: false });
    })

    // track Enter and Esc keys in modal view
    document.addEventListener('keydown', this.handleKeyEvents);

    // reset this.orgDup when organization value changes after entering duplicate
    // also, track changes in both organization and organizationModal input fields to update each other
    this.registerForm.get('organization').valueChanges.subscribe(() => {
      this.registerForm.get('organizationModal').setValue(this.registerForm.get('organization').value, { emitEvent: false });
      this.orgDup = false;
    })
    this.registerForm.get('organizationModal').valueChanges.subscribe(() => {
      this.registerForm.get('organization').setValue(this.registerForm.get('organizationModal').value.trim(), { emitEvent: false });
      this.orgDup = false;
    })

    // track password validation
    this.registerForm.get('password').valueChanges.subscribe(() => {
      this.registerForm.get('cpassword').updateValueAndValidity(); // trigger re-validation
    })
  }

  ngOnDestroy(): void {
    document.removeEventListener('keydown', this.handleKeyEvents);
  }

  // method to handle key events
  handleKeyEvents = (event: KeyboardEvent) => {
    if (this.showModal) {
      if (event.key === 'Enter') {
        this.searchSchools(); // trigger search on Enter
      } else if (event.key === 'Escape') {
        this.closeModal(); // close modal on Esc
      }
    }
  }

  // activate search function according to selected organization type
  onSearchClick(): void {
    if (this.rf['typeSelect'].value === 'school') {
      this.searchSchools();
      this.showModal = true;
    } else {
      return;
    }
  }

  // search for schools based on entered organization name
  searchSchools(): void {
    const orgName = this.rf['organizationModal'].value.trim(); // trim leading and trailing whitespace
    this.orgDup = false;

    if (!orgName) {
      return;
    }

    this.schoolSearchService.getSchools(orgName).subscribe(
      (response) => {
        if (response.schoolInfo) {
          const schoolData = response.schoolInfo[1].row;
          this.orgSearchResults = schoolData ? schoolData : [];
          this.updatePagination();
        } else {
          console.error('no schools matching the queried name were found.');
          this.orgSearchResults = []; // no results
        }
      },
      (error) => {
        console.error('error fetching schools:', error);
        this.orgSearchResults = []; // no results
      }
    )
  }

  // update pagination based on results
  updatePagination(): void {
    this.totalPages = Math.ceil(this.orgSearchResults.length / this.pageSize);
    this.currentPage = 1;
    this.setPageIndices();
  }

  // set start and end index for current page
  setPageIndices(): void {
    this.startIndex = (this.currentPage - 1) * this.pageSize;
    this.endIndex = Math.min(this.startIndex + this.pageSize, this.orgSearchResults.length);
    this.paginatedResults = this.orgSearchResults.slice(this.startIndex, this.endIndex);
  }

  // pagination controls
  nextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.setPageIndices();
    }
  }

  prevPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.setPageIndices();
    }
  }

  // open school search results modal
  openModal(): void {
    this.onSearchClick();
  }

  // close school search results modal
  closeModal(): void {
    this.showModal = false;
  }

  // select a school and close modal
  selectSchool(school: any): void {
    this.orgCode = Number(school.SD_SCHUL_CODE);
    this.checkSchoolDup(school);
  }

  // check if an admin for the selected school already exists in localStorage
  checkSchoolDup(school: any): void {
    const schoolId = this.orgCode;
    this.organizationService.validOrganization(schoolId).subscribe({
      error: (response) => {
        if (response.status != 1) {
          this.orgDup = true;
          console.error('해당 기관은 이미 존재합니다. 기존 계정으로 로그인 하십시오.');
          return;
        }
      }
    });
    this.orgDup = false;
    this.rf['organization'].setValue(school.SCHUL_NM);
    this.selectedOrg = school;
    this.closeModal();
  }

  checkOrgDup(): void {
    if (this.rf['organization'].errors?.['required']) {
      return;
    }
    const orgName = this.rf['organizationModal'].value.trim();
    this.organizationService.validOrganizationName(orgName).subscribe({
      error: (response) => {
        if (response.status != 1) {
          this.orgDup = true;
          this.chkOrgDup = false;
          console.error('해당 기관은 이미 존재합니다. 기존 계정으로 로그인 하십시오.');
          return;
        }
      }
    });
    this.orgDup = false;
    this.chkOrgDup = true;
    this.selectedOrg = orgName;
  }

  onInputChange(event: any): void {
    this.chkOrgDup = false;
  }

  onFileChange(event: any) {
    const input = event.target as HTMLInputElement;
    const files = input.files;

    if (files) {
      // check number of files
      if (this.uploadedFiles.length + files.length > this.maxFiles) {
        alert(`최대 ${this.maxFiles}개의 파일만 첨부 가능합니다.`);
        return;
      }

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const fileType = file.type;
        const validTypes = ['application/pdf', 'image/jpeg', 'image/png'];

        // check file size
        if (file.size > this.maxFileSize) {
          alert(`${file.name} 파일의 크기가 ${this.maxFileSize / (1024 * 1024)}MB를 초과합니다.`);
          continue;
        }

        if (validTypes.includes(fileType)) {
          this.uploadedFiles.push(file);
          this.fileUploaded = true;
        } else {
          alert(`${file.name}은 지원되지 않는 파일 형식입니다.`);
        }
      }
    } else {
      this.fileUploaded = false;
    }
  }

  removeFile(index: number): void {
    this.uploadedFiles.splice(index, 1);
    if (this.uploadedFiles.length === 0) {
      this.fileUploaded = false;
    }
  }

  registOrganization(): void {
    if (this.rf['typeSelect'].value === 'school') {
      this.formSubmitted = true;
      if (this.checkUsernameDup !== this.registerForm.value.username) {
        alert("아이디 중복 확인을 해주세요.");
      }
      else {
        if (this.hasAgreeAll() && this.registerForm.valid) {
          try {
            this.organizationService.registOrganization(this.rf['username'].value, this.rf['email'].value, this.rf['fullname'].value, this.rf['password'].value, `${this.rf['cnum1'].value}-${this.rf['cnum2'].value}-${this.rf['cnum3'].value}`, this.selectedOrg.SD_SCHUL_CODE, this.selectedOrg.SCHUL_NM, this.selectedOrg.ATPT_OFCDC_SC_CODE, this.selectedOrg.ATPT_OFCDC_SC_NM, this.selectedOrg.ORG_RDNZC, this.selectedOrg.ORG_RDNMA, this.selectedOrg.ORG_RDNDA, this.uploadedFiles[0]).subscribe(data => {
              // if (data.status != 1) {
              console.log(data.status);
              // }
            });
          } catch (error) {
            console.error('signup error:', error);
            this.errorExists = true;
          }
        } else {
          this.errorExists = true;
        }
      }
    } else if (this.rf['typeSelect'].value === 'company') {
      this.formSubmitted = true;
      if (this.checkUsernameDup !== this.registerForm.value.username) {
        alert("아이디 중복 확인을 해주세요.");
      }
      else {
        if (this.hasAgreeAll() && this.registerForm.valid) {
          try {
            this.organizationService.registOrganizationName(this.rf['username'].value, this.rf['email'].value, this.rf['fullname'].value, this.rf['password'].value, `${this.rf['cnum1'].value}-${this.rf['cnum2'].value}-${this.rf['cnum3'].value}`, this.rf['organization'].value, this.uploadedFiles[0]).subscribe(data => {
              // if (data.status != 1) {
              console.log(data.status);
              // }
            });
          } catch (error) {
            console.error('signup error:', error);
            this.errorExists = true;
          }
        } else {
          this.errorExists = true;
        }
      }
    }
    else {
      return;
    }
  }

  onSubmit() {
    this.formSubmitted = true;

    // stop here if form is invalid or file is not uploaded
    if (this.registerForm.invalid || !this.fileUploaded) {
      return;
    }

    this.registOrganization();
    this.rf['organization'].setValue(this.orgCode);
  }

  get rf() { return this.registerForm.controls; }

  changeCheckbox() {
    this.checkbox = !this.checkbox;
  }

  // method to check username for duplicates
  checkUsernameUnique() {
    const usernameValue = this.rf['username'].value;

    if (usernameValue.length < 6) {
      // alert("아이디가 너무 짧습니다.");
    } else {
      this.checkUsernameText = "확인중...";
      this.userService.validUsername(usernameValue).subscribe(
        data => {
          this.rf['usernameCheckedUnique'].setValue(true);

          if (data.res_code != 1) {
            this.dupUsername = usernameValue;
            this.usernameExists = true;
            // alert("이미 사용중인 아이디 입니다. 다른 아이디를 입력해 주세요.");
          } else {
            this.usernameExists = false; // reset flag
            // alert("사용 가능한 아이디 입니다.");
            this.checkUsernameDup = usernameValue;
          }
        },
        error => {
          this.usernameExists = true;
          this.rf['usernameCheckedUnique'].setValue(true);
          // alert("이미 사용중인 아이디 입니다. 다른 아이디를 입력해 주세요.");
        },
        () => {
          this.checkUsernameText = "중복확인";
        }
      );
    }
  }

  // method to check email for duplicates
  checkEmailUnique(): void {
    const emailValue = this.rf['email'].value;

    // return early if email is empty
    if (!emailValue?.trim()) {
      this.checkEmailText = ''; // reset status text
      this.rf['emailCheckedUnique'].setValue(false);
      this.emailExists = false;
      return;
    }

    this.checkEmailText = '확인중...'; // status: checking
    this.userService.validEmail(emailValue).subscribe(
      (data) => {
        this.rf['emailCheckedUnique'].setValue(true);

        if (data.res_code === 1) {
          this.emailExists = false; // email is unique
          this.checkEmailText = '사용 가능한 이메일입니다.';
        } else {
          this.dupEmail = emailValue;
          this.emailExists = true; // email exists
          this.checkEmailText = '이미 사용중인 이메일입니다.';
        }
      },
      () => {
        this.emailExists = true;
        this.rf['emailCheckedUnique'].setValue(true);
        this.checkEmailText = '이메일 확인 중 오류가 발생했습니다.';
      }
    );
  }

  // method to toggle visibility in password input field
  togglePasswordVisibility(): void {
    this.passwordVisible = !this.passwordVisible;

    // clear previous timeout
    if (this.visibilityTimeout) {
      clearTimeout(this.visibilityTimeout);
    }

    // set timeout to obfuscate password after 3 seconds
    if (this.passwordVisible) {
      this.visibilityTimeout = setTimeout(() => {
        this.passwordVisible = false;
      }, 3000); // hide password after 3 seconds
    }
  }

  fileUpload(e) {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      this.youtubeSubImg = file;
    }
  }

  // method to move to next input field after current field is filled
  autoMoveToNextField(event: KeyboardEvent, nextFieldId: string, maxLength: number) {
    const target = event.target as HTMLInputElement;
    if (target.value.length === maxLength) {
      const nextField = document.getElementById(nextFieldId) as HTMLInputElement;
      if (nextField) {
        nextField.focus();
      }
    }
  }

  // method to move to previous field when backspace is pressed on an empty field
  autoMoveToPreviousField(event: KeyboardEvent, prevFieldId: string) {
    const target = event.target as HTMLInputElement;
    if (event.key === 'Backspace' && target.value.length === 0) {
      const prevField = document.getElementById(prevFieldId) as HTMLInputElement;
      if (prevField) {
        prevField.focus();
      }
    }
  }

  // sanitize input to allow only numeric values
  sanitizeInput(event: any, maxLength: number) {
    const input = event.target;
    // if input contains any non-numeric characters, replace them
    let sanitizedValue = input.value.replace(/[^0-9]/g, '');

    // limit input to specified max length
    if (sanitizedValue.length > maxLength) {
      sanitizedValue = sanitizedValue.slice(0, maxLength);
    }

    input.value = sanitizedValue;

    // update form control with sanitized value
    this.registerForm.get(input.id).setValue(sanitizedValue);
  }

  gotoRegisterStep(dst: number): void {
    if (dst < 1 || dst > 3) {
      console.log('invalid register step number.');
      return;
    }

    this.scrollTop();

    if (dst === 2) {
      this.agreeSubmitted = true;
      if (!this.hasAgreeAll()) {
        return;
      }
    }

    if (dst === 3) {
      this.formSubmitted = true;
      if (!(this.registerForm.valid && this.fileUploaded)) {
        return;
      }

      if (!this.chkOrgDup) {
        return;
      }

      this.onSubmit();
    }

    this.registerStep = dst;
  }

  rotateChevron(chevronId: string, isDropdownOpen: boolean): void {
    const chevron = document.getElementById(chevronId);
    if (chevron) {
      chevron.style.transform = isDropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)';
      chevron.style.transition = 'transform 0.3s ease';
    }
  }

  toggleTosDropdown(): void {
    this.showTos = !this.showTos;
    this.rotateChevron('tosChevron', this.showTos);
    this.tosAgree ? this.agree([this._agreeTos]) : this.disagree([this._agreeTos]);
  }

  togglePrivTermsDropdown(): void {
    this.showPrivTerms = !this.showPrivTerms;
    this.rotateChevron('privChevron', this.showPrivTerms);
    this.privAgree ? this.agree([this._agreePriv]) : this.disagree([this._agreePriv]);
  }

  toggleMemTermsDropdown(): void {
    this.showMemTerms = !this.showMemTerms;
    this.rotateChevron('memChevron', this.showMemTerms);
    this.memAgree ? this.agree([this._agreeMem]) : this.disagree([this._agreeMem]);
  }

  toggleAgreeTos(): void {
    this.tosAgree = !this.tosAgree;
    this.tosAgree ? this.agree([this._agreeTos]) : this.disagree([this._agreeTos]);
    this.modifyAgreeAll();
  }

  toggleAgreePriv(): void {
    this.privAgree = !this.privAgree;
    this.privAgree ? this.agree([this._agreePriv]) : this.disagree([this._agreePriv]);
    this.modifyAgreeAll();
  }

  toggleAgreeMem(): void {
    this.memAgree = !this.memAgree;
    this.memAgree ? this.agree([this._agreeMem]) : this.disagree([this._agreeMem]);
    this.modifyAgreeAll();
  }

  toggleAgreeAll(): void {
    if (this.allAgree) {
      this.allAgree = this.tosAgree = this.privAgree = this.memAgree = false;
      this.disagree([this._agreeAll, this._agreeTos, this._agreePriv, this._agreeMem]);
    } else {
      this.agree([this._agreeAll, this._agreeTos, this._agreePriv, this._agreeMem]);
      this.allAgree = this.tosAgree = this.privAgree = this.memAgree = true;
    }
  }

  agree(els: ElementRef[]): void {
    if (els) {
      els.forEach(el => {
        if (el) {
          el.nativeElement.querySelector('img').src = "../../../../assets/icons/Icon_64px_col-36.png"
        }
      });
    }
  }

  disagree(els: ElementRef[]): void {
    if (els) {
      els.forEach(el => {
        if (el) {
          el.nativeElement.querySelector('img').src = "../../../../assets/icons/Icon_64px_col-35.png"
        }
      });
    }
  }

  modifyAgreeAll(): void {
    if (this.hasAgreeAll()) {
      this.allAgree = true;
      this.agree([this._agreeAll]);
    } else {
      this.allAgree = false;
      this.disagree([this._agreeAll]);
    }
  }

  hasAgreeAll(): boolean {
    if (this.tosAgree && this.privAgree && this.memAgree) {
      this.rf['acceptTerms'].setValue(true);
    }
    return this.tosAgree && this.privAgree && this.memAgree;
  }

  gotoHome(): void {
    this.router.navigate(['/home']);
    this.scrollTop();
  }

  gotoLogin(): void {
    this.router.navigate(['/login']);
    this.scrollTop();
  }

  scrollTop(): void {
    $('html,body').stop().animate({
      scrollTop: 0
    });
  }
}
